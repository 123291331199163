import moment from 'moment';
import * as actionTypes from 'store/action-types';
import { IAction } from 'shared/interface/state';
import { IFSFilterParams } from 'shared/interface';

export const fsFilterInitialState: IFSFilterParams = {
	year: moment().year(),
	month: moment().month() - 1,
	region: null,
	dealer_code: null
};

const financialReducer = (state = fsFilterInitialState, action: IAction): IFSFilterParams => {
	switch (action.type) {
		case actionTypes.FS_FILTER:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default financialReducer;
