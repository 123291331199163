import { FC } from 'react';
import { Box, TextFieldProps, BoxProps, TypographyProps, SxProps } from '@mui/material';
import { StyledTextInput, StyledInputLabel, AsteriskStyleIcon } from './MMSCANTextField.style';

interface IProps {
	label: string;
	boxProps?: BoxProps;
	labelProps?: TypographyProps;
	inputPropsTestId?: string;
	isdark?: boolean;
	labelSx?: SxProps;
}

const MMSCANTextField: FC<IProps & TextFieldProps> = (props) => {
	const { label, boxProps, labelProps, labelSx, inputPropsTestId, required, isdark = false, ...rest } = props;

	return (
		<Box width='100%' {...boxProps}>
			<StyledInputLabel sx={{ ...labelSx }} {...labelProps}>
				{label} {required && <AsteriskStyleIcon />}
			</StyledInputLabel>
			<StyledTextInput
				size='small'
				variant='outlined'
				fullWidth
				isdark={isdark}
				inputProps={{ 'data-testid': inputPropsTestId }}
				{...rest}
			/>
		</Box>
	);
};

export default MMSCANTextField;
