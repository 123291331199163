import { IModuleDetails } from 'features/auth/login/LoginScreen.type';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import MenuItems from 'hoc/layout/component/MenuItems';
import { MenuItemPropsTypes } from 'hoc/layout/Layout.types';
import { ROUTES } from 'shared/constants/constant';
import { IState } from 'shared/interface/state';
import { checkPermission } from 'shared/util/Utility';

interface IProps {
	module: string;
	permission: string;
}

// Higher-Order Component (HOC) for route protection
const ProtectedRoute: FC<IProps> = (props) => {
	const { module } = props;
	const menuItems = MenuItems();

	const authData = useSelector((state: IState) => state.auth);
	const modules = authData.modules as IModuleDetails[];
	const [menuListItem, setMenuListItem] = useState<MenuItemPropsTypes[]>([]);

	useEffect(() => {
		const filteredMenuItems = modules
			?.map((module) => module.permissions.length > 0 && menuItems[module.module_name])
			.filter(Boolean);
		filteredMenuItems && setMenuListItem([...(filteredMenuItems as MenuItemPropsTypes[])]);
	}, [modules]);

	// Render the route if the user has permission, otherwise, redirect
	if (!checkPermission(module)) {
		// if user does not have permission, redirect to Access denied page
		return <Navigate to={(menuListItem[0] as MenuItemPropsTypes)?.url || ROUTES.ACCESS_DENIED} />;
	}
	return <Outlet />;
};

export default ProtectedRoute;
