import { FC } from 'react';
import { styled, Typography, Box, CircularProgress } from '@mui/material';
import { colors } from 'assets/styles/MMSCANTheme';

interface IProps {
	loaderMessage?: string;
	isLeftAlign?: boolean;
	loaderHeight?: string;
	loaderMsgFontSize?: string | number;
}

const loaderWrapperStyles = (height: string) => ({
	width: '100%',
	height: height || '100%',
	minHeight: '200px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center'
});

const MMSCANLoader: FC<IProps> = (props) => {
	const { loaderMessage, loaderHeight = '100%', loaderMsgFontSize } = props;
	const StyledTypography = styled(Typography)({
		marginTop: '10px',
		fontSize: loaderMsgFontSize || '20px',
		color: colors.black.main
	}) as typeof Typography;

	return (
		<Box
			sx={
				props.isLeftAlign === true
					? { ...loaderWrapperStyles(loaderHeight), marginLeft: '8%', alignItems: 'flex-start' }
					: loaderWrapperStyles(loaderHeight)
			}
		>
			<CircularProgress data-testid='loader' color='primary' />
			{loaderMessage && (
				<StyledTypography data-testid='loaderMessage' className='loader-message'>
					{loaderMessage}
				</StyledTypography>
			)}
		</Box>
	);
};

export default MMSCANLoader;
