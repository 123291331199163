import { FC, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import {
	AccessDenied,
	AgendaActionContainer,
	BusinessPlan,
	BusinessPlanDetails,
	BusinessPlanHistory,
	ChangePassword,
	ChangeRequest,
	ClosedData,
	Dashboard,
	DealerFormScreen,
	DealerSessionManagement,
	FinancialStatement,
	MyProfile,
	Reports,
	ReportsContainer,
	SubmissionTable,
	User,
	UserFormScreen,
	VisitFormScreen,
	VisitManagement
} from 'hoc/asyncComponents';
import Layout from 'hoc/layout/Layout';
import ProtectedRoute from 'hoc/protectedRoute/ProtectedRoute';
import PublicLayout from 'hoc/publicLayout/PublicLayout';
import * as actionTypes from 'store/action-types';

import MMSCANLoader from 'shared/components/MMSCANLoader/MMSCANLoader';
import AuthService from 'shared/services/Auth.service';
import { createAction } from 'shared/util/Utility';

import ForgotPasswordScreen from 'features/auth/forgot-password/ForgotPasswordScreen';
import LoginScreen from 'features/auth/login/LoginScreen';
import ResetPasswordScreen from 'features/auth/reset-password/ResetPasswordScreen';
import VerifyAccountScreen from 'features/auth/verify-account/verifyAccount';

import { MODULE, PERMISSION, ROUTES, titleMap } from 'shared/constants/constant';
import { IAction, IState } from 'shared/interface/state';

const App: FC = () => {
	const authInfo = useSelector((state: IState) => state.auth);
	const { isLoggedIn } = authInfo;

	const [pageTitle, setPageTitle] = useState('BizDrive');
	const curLoc = useLocation();

	const dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, IAction> = useDispatch();

	useEffect(() => {
		// Function to handle changes in localStorage
		const handleStorageChange = () => {
			const currentAuthData = localStorage.getItem('authData');
			if (currentAuthData === null) {
				dispatch(createAction(actionTypes.AUTH_FAIL));
			} else {
				dispatch(createAction(actionTypes.AUTH_SUCCESS, AuthService.getAuthData()));
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [dispatch]);

	useEffect(() => {
		const curTitle = titleMap.find((item) => item.path === curLoc.pathname);
		if (curTitle && curTitle.title) {
			setPageTitle(curTitle.title);
			document.title = curTitle.title;
		}
	}, [curLoc]);

	if (isLoggedIn) {
		return (
			<Layout>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<Suspense fallback={<MMSCANLoader />}>
					<Routes>
						<Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
						<Route path={ROUTES.MY_PROFILE} element={<MyProfile />} />
						<Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />

						<Route element={<ProtectedRoute module={MODULE.USER} permission={PERMISSION.LIST} />}>
							<Route path={ROUTES.USER} element={<User />} />
						</Route>
						<Route element={<ProtectedRoute module={MODULE.USER} permission={PERMISSION.ADD} />}>
							<Route path={ROUTES.ADD_USER} element={<UserFormScreen />} />
						</Route>
						<Route element={<ProtectedRoute module={MODULE.USER} permission={PERMISSION.UPDATE} />}>
							<Route path={`${ROUTES.EDIT_USER}/:userId`} element={<UserFormScreen />} />
						</Route>

						<Route
							element={
								<ProtectedRoute
									module={MODULE.DEALER_SESSION_MANAGEMENT}
									permission={PERMISSION.LIST}
								/>
							}
						>
							<Route path={ROUTES.DEALER_SESSION_MANAGEMENT} element={<DealerSessionManagement />} />
						</Route>
						<Route
							element={
								<ProtectedRoute module={MODULE.DEALER_SESSION_MANAGEMENT} permission={PERMISSION.ADD} />
							}
						>
							<Route path={ROUTES.ADD_DEALER} element={<DealerFormScreen />} />
						</Route>
						<Route
							element={
								<ProtectedRoute
									module={MODULE.DEALER_SESSION_MANAGEMENT}
									permission={PERMISSION.UPDATE}
								/>
							}
						>
							<Route path={`${ROUTES.EDIT_DEALER}/:dealerId`} element={<DealerFormScreen />} />
						</Route>
						<Route element={<ProtectedRoute module={MODULE.REPORTS} permission={PERMISSION.VIEW} />}>
							<Route path={ROUTES.REPORTS} element={<ReportsContainer />} />
							<Route path={ROUTES.NEW} element={<Reports />} />
							<Route path={ROUTES.PARTS} element={<Reports />} />
							<Route path={ROUTES.USED} element={<Reports />} />
							<Route path={ROUTES.SERVICE} element={<Reports />} />
							<Route path={ROUTES.BODY_SHOP} element={<Reports />} />
							<Route path={ROUTES.TOTAL_OPERATIONS} element={<Reports />} />
							<Route path={ROUTES.BALANCE_SHEET} element={<Reports />} />
							<Route path={ROUTES.WORKING_CAPITAL} element={<Reports />} />
						</Route>

						<Route element={<ProtectedRoute module={MODULE.CLOSED_DATA} permission={PERMISSION.VIEW} />}>
							<Route path={ROUTES.CLOSED_DATA} element={<ClosedData />} />
							<Route path={ROUTES.CHANGE_REQUEST} element={<ChangeRequest />} />
						</Route>

						<Route
							element={
								<ProtectedRoute module={MODULE.FINANCIAL_STATEMENT} permission={PERMISSION.VIEW} />
							}
						>
							<Route path={ROUTES.SUBMISSION} element={<SubmissionTable />} />
							<Route path={ROUTES.FINANCIAL_STATEMENT} element={<FinancialStatement />} />
						</Route>

						<Route
							element={<ProtectedRoute module={MODULE.VISIT_MANAGEMENT} permission={PERMISSION.LIST} />}
						>
							<Route path={ROUTES.VISIT_MANAGEMENT} element={<VisitManagement />} />
							<Route path={ROUTES.VISIT} element={<VisitFormScreen />} />
							<Route path={`${ROUTES.VISIT}/:visitId`} element={<VisitFormScreen />} />
							<Route
								path={`${ROUTES.VISIT}/:visitId/${ROUTES.AGENDA_ACTION}`}
								element={<AgendaActionContainer />}
							/>
							<Route
								path={`${ROUTES.VISIT}/:visitId/${ROUTES.AGENDA_ACTION}/view`}
								element={<AgendaActionContainer />}
							/>
							<Route
								path={`${ROUTES.VISIT}/:visitId/${ROUTES.AGENDA_ACTION}/${ROUTES.ACTION}/add`}
								element={<AgendaActionContainer />}
							/>
							<Route
								path={`${ROUTES.VISIT}/:visitId/${ROUTES.AGENDA_ACTION}/${ROUTES.ACTION}/:actionId/edit`}
								element={<AgendaActionContainer />}
							/>
							<Route
								path={`${ROUTES.VISIT}/:visitId/${ROUTES.AGENDA_ACTION}/${ROUTES.ACTION}/:actionId/view`}
								element={<AgendaActionContainer />}
							/>
							<Route
								path={`${ROUTES.VISIT}/:visitId/${ROUTES.AGENDA_ACTION}/view/${ROUTES.ACTION}/:actionId/view`}
								element={<AgendaActionContainer />}
							/>
						</Route>

						<Route
							element={<ProtectedRoute module={MODULE.VISIT_MANAGEMENT} permission={PERMISSION.LIST} />}
						>
							<Route path={ROUTES.BUSINESS_PLAN} element={<BusinessPlan />} />
							<Route
								path={`${ROUTES.BUSINESS_PLAN_DETAILS}/:businessPlanId`}
								element={<BusinessPlanDetails />}
							/>
							<Route
								path={`${ROUTES.BUSINESS_PLAN_DETAILS}/:businessPlanId/view`}
								element={<BusinessPlanDetails />}
							/>
							<Route
								path={`${ROUTES.BUSINESS_PLAN_HISTORY}/:businessPlanId`}
								element={<BusinessPlanHistory />}
							/>
						</Route>

						<Route path={ROUTES.ACCESS_DENIED} element={<AccessDenied />} />
						<Route path='*' element={<Navigate replace to={ROUTES.DASHBOARD} />} />
					</Routes>
				</Suspense>
			</Layout>
		);
	} else {
		return (
			<>
				<PublicLayout>
					<Routes>
						<Route path={ROUTES.LOGIN} element={<LoginScreen />} />
						<Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordScreen />} />
						<Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordScreen />} />
						<Route path={ROUTES.VERIFY_ACCOUNT} element={<VerifyAccountScreen />} />
						<Route path='*' element={<Navigate replace to={ROUTES.LOGIN} />} />
					</Routes>
				</PublicLayout>
			</>
		);
	}
};

export default App;
