import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Notification: React.FC = () => {
	return (
		<ToastContainer
			position='bottom-right'
			autoClose={3000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			draggable
			pauseOnHover
		/>
	);
};

type NotificationType = 'success' | 'error';
export const notify = (message: string, type?: NotificationType) => {
	const msg = `${(message[0] || '').toUpperCase()}${message.substring(1)}`;

	if (type === 'success') {
		toast.success(msg, { toastId: 'success' });
	} else if (type === 'error') {
		toast.error(msg, { toastId: 'error' });
	}
};

export default Notification;
