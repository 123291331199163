import * as actionTypes from 'store/action-types';
import { IAction, IVisitState } from 'shared/interface/state';
import { ICommonPagination } from 'shared/interface';
import { IVisitFilterParams } from '../VisitManagement.type';

export const visitDataInitialState: IVisitState = {
	pagination: {} as ICommonPagination,
	filter: {} as IVisitFilterParams
};

const visitReducer = (state: IVisitState = visitDataInitialState, action: IAction): IVisitState => {
	switch (action.type) {
		case actionTypes.VISIT_DATA:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default visitReducer;
