import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ErrorHandler from 'hoc/withErrorHandler/ErrorHandler';
import ErrorBoundary from 'shared/components/errorBoundary/ErrorBoundary';
import App from 'App';
import store from 'store';
import { theme } from 'assets/styles/MMSCANTheme';
import 'assets/styles/app.scss';
import 'react-datasheet-grid/dist/style.css';

const Root: React.FC = () => {
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<ThemeProvider theme={theme}>
						<ErrorHandler />
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ThemeProvider>
				</LocalizationProvider>
			</Provider>
		</ErrorBoundary>
	);
};

export default Root;
