import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as actionTypes from 'store/action-types';

import MMSCANButton from 'shared/components/MMSCANButton/MMSCANButton';
import { createAction } from 'shared/util/Utility';
import { DEFAULT_PARAMS } from 'shared/constants/constant';
import { IAction } from 'shared/interface/state';
import { fsFilterInitialState } from 'features/financialStatement/store/financialStatement.reducer';
import { CSSObject } from 'styled-components';
import { colors } from 'assets/styles/MMSCANTheme';

const autoRefreshWrapperStyles: CSSObject = {
	width: '100%',
	height: '100%',
	position: 'fixed',
	top: 0,
	right: 0,
	zIndex: 12002,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: colors.black.shade3
};

const autoRefreshBox: CSSObject = {
	maxWidth: '500px',
	padding: '20px',
	bgcolor: colors.white.main,
	borderRadius: '4px'
};

const AutoRefresh = () => {
	const dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, IAction> = useDispatch();

	const handleLogout = useCallback(() => {
		dispatch(createAction(actionTypes.AUTH_FAIL));
		dispatch(createAction(actionTypes.CLEAR_DATA));
		// Define default parameters once
		const defaultParams = DEFAULT_PARAMS;
		const emptyFilter = {};
		const dataPayload = { pagination: defaultParams, filter: emptyFilter };
		const actionsWithDefaultParams = [
			actionTypes.USER_DATA,
			actionTypes.DEALER_DATA,
			actionTypes.VISIT_DATA,
			actionTypes.AGENDA_ACTION,
			actionTypes.ACTION_SCREEN,
			actionTypes.BUSINESS_PLAN
		];

		// Dispatch actions with default parameters
		actionsWithDefaultParams.forEach((action) => {
			dispatch(createAction(action, dataPayload));
		});
		dispatch(createAction(actionTypes.FS_FILTER, { ...fsFilterInitialState }));
		dispatch(createAction(actionTypes.CLOSED_DATA, { year: moment().year() }));
	}, [dispatch]);

	return createPortal(
		<Box sx={autoRefreshWrapperStyles}>
			<Box sx={autoRefreshBox}>
				<Typography sx={{ marginBottom: '10px', textAlign: 'center' }}>New updates are available!</Typography>
				<Typography sx={{ margin: '10px 0 20px 0', textAlign: 'center' }}>
					Please log out and back in to see the latest changes.
				</Typography>
				<MMSCANButton
					sx={{ display: 'flex', margin: '0 auto !important', padding: '4px 20px' }}
					text='Ok'
					type='button'
					onClick={() => handleLogout()}
				/>
			</Box>
		</Box>,
		document.body
	);
};

export default AutoRefresh;
