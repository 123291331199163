import { FC, ImgHTMLAttributes } from 'react';
import { styled, Drawer as MuiDrawer, Theme, CSSObject, IconButton, SxProps } from '@mui/material';
import { MenuItemPropsTypes } from './Layout.types';
import { colors } from 'assets/styles/MMSCANTheme';

export const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: 'ease',
		duration: '0.3s'
	}),
	overflowX: 'hidden',
	justifyContent: 'space-between'
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: 'ease',
		duration: '0.3s'
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(10)})`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(10)})`
	},
	justifyContent: 'space-between'
});

const closedHeader = () => ({
	height: '45px',
	margin: '11px 0 0',
	flexDirection: 'column'
});

export const DrawerHeader = styled('div', {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }: any) => ({
	display: 'flex',
	alignItems: 'flex-end',
	...theme.mixins.toolbar,
	...closedHeader()
})) as any;

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	justifyContent: 'space-between',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
})) as typeof MuiDrawer;

export const StyledIconButton: any = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== 'open'
})(() => ({
	backgroundColor: colors.primary.main,
	color: colors.white.main,
	'&:hover': {
		backgroundColor: colors.primary.main
	},
	borderRadius: '3px 0 0 3px',
	width: '42px',
	marginTop: '13px'
}));

export const leftPanelTextRoot = {
	paddingLeft: '20px',
	bottom: '10px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	position: 'absolute'
} as SxProps;

export const leftPanelText = {
	fontSize: '12px',
	fontWeight: '500',
	marginBottom: '15px',
	letterSpacing: '0.01em',
	textAlign: 'left',
	color: colors.secondary.shade7
} as SxProps;

export const DrawerBoxStyles = () => ({
	height: '60px',
	width: '80px',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
});

export const ChildrenBoxStyles = (open: boolean) => ({
	width: open ? 'calc(100vw - 250px)' : 'calc(100vw - 80px)',
	backgroundColor: colors.secondary.shade4,
	minHeight: '100vh',
	position: 'relative',
	transition: 'width 0.3s ease'
});

export const MenuPaperProps = {
	elevation: 0,
	sx: {
		overflow: 'visible',
		filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
		bottom: '70px',
		left: '24px !important',
		top: 'unset !important',
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: '-10px',
			right: 0,
			left: '12px',
			width: 10,
			height: 10,
			bgcolor: 'background.paper',
			transform: 'translateY(-50%) rotate(45deg)',
			zIndex: 0
		}
	}
};

export const CollapseWrapperStyles = (
	open: boolean,
	openCollapsibleReports: boolean,
	menuListItem: MenuItemPropsTypes[]
) => ({
	top: !open && openCollapsibleReports ? `${69 + menuListItem.length * 50}px` : 'unset',
	display: !open && !openCollapsibleReports ? 'none' : 'block',
	boxShadow: !open && openCollapsibleReports ? '0px 5px 7px -1px rgb(28 48 74 / 22%)' : 'unset',
	backgroundColor: colors.black.main
});

export const ChildListItemButtonStyles = (open: boolean, isSelected: boolean) => ({
	height: '40px',
	justifyContent: open ? 'initial' : 'center',
	backgroundColor: colors.black.main,
	color: isSelected ? colors.primary.main : colors.white.main,
	transition: 'all 0.3s ease',
	borderLeft: isSelected ? '4px solid' : '4px solid transparent',
	':hover': {
		backgroundColor: colors.black.shade2
	}
});

export const ListItemButtonStyles = {
	height: '40px',
	justifyContent: 'center',
	borderLeft: '4px solid transparent',
	transition: 'all 0.3s ease',
	':hover': {
		backgroundColor: colors.black.shade2
	}
};

export const ListItemIconStyles = {
	mr: 'auto',
	color: '',
	justifyContent: 'center',
	height: '30px',
	width: '30px',
	display: 'flex',
	alignItems: 'center'
};

export const ListItemTextStyles = {
	wordBreak: 'break-word',
	maxWidth: '170px',
	margin: '0',
	display: 'flex',
	alignItems: 'center',
	overflow: 'hidden',
	lineHeight: 'normal'
};

export const NameInfoTextStyle = {
	textAlign: 'right',
	fontSize: '8px',
	color: colors.white.main,
	marginTop: '40px',
	display: 'flex',
	alignItems: 'center'
};

export const CopyRightIconStyle = {
	fontSize: '10px',
	color: colors.white.main,
	fontWeight: 400,
	marginTop: '-1px'
};

export const layoutStyles = { width: '100%', height: '100vh', display: 'flex', position: 'relative' } as SxProps;
export const layoutInnerWrapperStyles = {
	width: '100%',
	display: 'flex',
	position: 'absolute',
	padding: '0 20px'
} as SxProps;

export const bottomTextStyles = {
	width: '100%',
	maxWidth: '400px',
	fontSize: '20px',
	textAlign: 'center',
	fontWeight: 500,
	padding: '10px 30px',
	color: colors.white.main
} as SxProps;

export const loginFormWrapperStyle = {
	width: '40%',
	height: '100vh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center'
};
export const StyledImg: FC<ImgHTMLAttributes<HTMLImageElement>> = styled('img')({
	width: '100%',
	height: '100%',
	objectFit: 'contain',
	verticalAlign: 'bottom'
});
