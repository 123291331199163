import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { InputAdornment, Typography } from '@mui/material';
import { EmailSharp as EmailSharpIcon, ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
// Components
import { generateValidation } from 'shared/constants/validation';
import { API_CONFIG } from 'shared/constants/api';
import { ROUTES } from 'shared/constants/constant';
import MMSCANTextField from 'shared/components/MMSCANTextField/MMSCANTextField';
import MMSCANButton from 'shared/components/MMSCANButton/MMSCANButton';
import HttpService from 'shared/services/Http.service';
import { notify } from 'shared/components/notification/notification';
// Assets
import { CommonLinkSxProps, StyledAuthTitle } from 'assets/styles/Common.style';
import { colors } from 'assets/styles/MMSCANTheme';

interface IForgotPasswordFormInput {
	email: string;
}

const ForgotPasswordScreen: FC = () => {
	const navigate = useNavigate();
	const [isLoading, setLoading] = useState<boolean>(false);
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<IForgotPasswordFormInput>({
		defaultValues: { email: '' }
	});

	const submitForgotPasswordForm = async (params: IForgotPasswordFormInput) => {
		try {
			setLoading(true);
			const res = await HttpService.post(API_CONFIG.forgotPassword, params);
			notify(res.message, 'success');
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<StyledAuthTitle data-testid='forgotPasswordTitle'>Forgot Password</StyledAuthTitle>
			<form className='width--full' onSubmit={handleSubmit(submitForgotPasswordForm)}>
				<Controller
					control={control}
					rules={generateValidation('Email').email}
					name='email'
					render={({ field: { ref, onChange, ...rest } }) => (
						<MMSCANTextField
							label='Email'
							data-testid='email'
							placeholder='Enter Your Email'
							boxProps={{ marginBottom: '36px' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<EmailSharpIcon fontSize='small' />
									</InputAdornment>
								)
							}}
							onChange={({ target: { value } }) => onChange(value.trim())}
							error={!!errors.email}
							helperText={errors.email?.message}
							inputRef={ref}
							{...rest}
						/>
					)}
				/>
				<MMSCANButton
					data-testid='sendEmailButton'
					fullWidth
					text='Send Email'
					loading={isLoading}
					type='submit'
				/>
			</form>
			<Typography data-testid='backToLoginLink' sx={CommonLinkSxProps} onClick={() => navigate(ROUTES.LOGIN)}>
				Back to
				<Typography component='span' sx={{ fontSize: '12px', color: colors.primary.main }}>
					Login?
				</Typography>
				<ArrowRightAltIcon fontSize='small' />
			</Typography>
		</>
	);
};

export default ForgotPasswordScreen;
