import * as actionTypes from 'store/action-types';
import { IAction } from 'shared/interface/state';
import { IActionScreenState } from '../VisitManagement.type';

export const actionScreenInitialState: IActionScreenState = {
	screen: 'list'
};

const actionScreenReducer = (
	state: IActionScreenState = actionScreenInitialState,
	action: IAction
): IActionScreenState => {
	switch (action.type) {
		case actionTypes.ACTION_SCREEN:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default actionScreenReducer;
