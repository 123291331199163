import { FC } from 'react';
import { Alert, AlertColor } from '@mui/material';
import MMSCANButton from 'shared/components/MMSCANButton/MMSCANButton';

interface IActionAlertProps {
	message: string;
	severity: AlertColor;
	buttonProps?: {
		text: string;
		onClick: () => void;
		loading?: boolean;
		'data-testid'?: string;
		fullWidth?: boolean;
	};
}

const ActionAlert: FC<IActionAlertProps> = ({ message, severity, buttonProps }) => (
	<>
		<Alert variant='outlined' severity={severity} sx={{ marginBottom: '20px' }}>
			{message}
		</Alert>
		{buttonProps && <MMSCANButton {...buttonProps} />}
	</>
);

export default ActionAlert;
