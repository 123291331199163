import * as actionTypes from 'store/action-types';
import { IAction, INotificationState } from 'shared/interface/state';

export const notificationInitialState: INotificationState = {
	isCallAPI: false
};

const notificationReducer = (
	state: INotificationState = notificationInitialState,
	action: IAction
): INotificationState => {
	switch (action.type) {
		case actionTypes.NOTIFICATION_API_CALL:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default notificationReducer;
