import * as actionTypes from 'store/action-types';
import { IAction, ICommonState } from 'shared/interface/state';
import AuthService from 'shared/services/Auth.service';

export const commonInitialState: ICommonState = {
	selectedCompany: AuthService.getLocalStorage('selectedCompanyData')?.selectedCompany,
	selectedCompanyFields: AuthService.getLocalStorage('selectedCompanyData')?.selectedCompanyFields
};

const commonReducer = (state: ICommonState = commonInitialState, action: IAction): ICommonState => {
	switch (action.type) {
		case actionTypes.SELECTED_COMPANY_DATA:
			return {
				...state,
				...action.payload
			};
		case actionTypes.CLEAR_DATA:
			return commonInitialState;
		default:
			return state;
	}
};
export default commonReducer;
