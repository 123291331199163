import React, { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Box, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import {
	CopyrightRounded as CopyRightIcon,
	Key as KeyIcon,
	Logout as LogoutIcon,
	ManageAccounts as ManageAccountsIcon
} from '@mui/icons-material';

import { createAction, getFirstLetter } from 'shared/util/Utility';
import MMSCANDialog from 'shared/components/MMSCANDialog/MMSCANDialog';
import { DEFAULT_PARAMS, INITIAL_MODAL_STATE, ROUTES } from 'shared/constants/constant';
import { Modal } from 'shared/constants/enum';
import { IModalState } from 'shared/interface';
import * as actionTypes from 'store/action-types';
import { fsFilterInitialState } from 'features/financialStatement/store/financialStatement.reducer';
import { CopyRightIconStyle, MenuPaperProps, NameInfoTextStyle } from '../Layout.styles';
import * as Sx from './LayoutHeader.style';

interface IProfileMenuProps {
	isOpen: boolean;
	firstName: string;
	lastName: string;
	roleColor: string;
}

const ProfileMenu: React.FC<IProfileMenuProps> = ({ isOpen, firstName, lastName, roleColor }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [modalState, setModalState] = useState<IModalState>(INITIAL_MODAL_STATE);
	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);

	const handleLogout = () => {
		setActionLoading(true);
		dispatch(createAction(actionTypes.AUTH_FAIL));
		dispatch(createAction(actionTypes.CLEAR_DATA));
		// Define default parameters once
		const defaultParams = DEFAULT_PARAMS;
		const emptyFilter = {};
		const dataPayload = { pagination: defaultParams, filter: emptyFilter };
		const actionsWithDefaultParams = [
			actionTypes.USER_DATA,
			actionTypes.DEALER_DATA,
			actionTypes.VISIT_DATA,
			actionTypes.AGENDA_ACTION,
			actionTypes.ACTION_SCREEN,
			actionTypes.BUSINESS_PLAN
		];

		// Dispatch actions with default parameters
		actionsWithDefaultParams.forEach((action) => {
			dispatch(createAction(action, dataPayload));
		});
		dispatch(createAction(actionTypes.FS_FILTER, { ...fsFilterInitialState }));
		dispatch(createAction(actionTypes.CLOSED_DATA, { year: moment().year() }));
		setActionLoading(false);
	};

	const ProfileMenuItems = [
		{
			id: 'myProfileItem',
			label: 'My Profile',
			icon: <ManageAccountsIcon sx={Sx.icon} />,
			onClick: () => navigate(ROUTES.MY_PROFILE)
		},
		{
			id: 'changePWItem',
			label: 'Change Password',
			icon: <KeyIcon sx={Sx.icon} />,
			onClick: () => navigate(ROUTES.CHANGE_PASSWORD)
		},
		{
			id: 'actionItem',
			label: 'Logout',
			icon: <LogoutIcon sx={Sx.icon} />,
			onClick: () => setModalState({ open: true, type: Modal.Logout })
		}
	];

	return (
		<>
			<Box
				sx={{
					...Sx.infoRoot,
					margin: isOpen ? '6px 10px' : '6px 7px',
					justifyContent: 'center'
				}}
				data-testid='userInfo'
			>
				<Box
					sx={{
						...Sx.profileName,
						border: `1px solid ${roleColor}`
					}}
					onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
				>
					<Typography sx={{ fontSize: '20px', fontWeight: 500, textTransform: 'uppercase' }}>
						{getFirstLetter(`${firstName} ${lastName}`)}
					</Typography>
				</Box>
				{isOpen && (
					<Box sx={Sx.infoBox} data-testid='nameInfo'>
						<Typography sx={NameInfoTextStyle}>
							<CopyRightIcon sx={CopyRightIconStyle} /> {moment().year()} BizDrive. Powered by AutoRuptiv
						</Typography>
					</Box>
				)}
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={openMenu}
				onClick={() => setAnchorEl(null)}
				PaperProps={{
					...MenuPaperProps,
					sx: {
						...MenuPaperProps.sx,
						left: !isOpen ? '15px !important' : '24px !important'
					}
				}}
			>
				{ProfileMenuItems.map((item) => (
					<MenuItem key={item.id} sx={Sx.menuItem} onClick={item.onClick}>
						<ListItemIcon>{item.icon}</ListItemIcon>
						{item.label}
					</MenuItem>
				))}
			</Menu>

			{modalState.open && modalState.type === Modal.Logout && (
				<MMSCANDialog
					loading={actionLoading}
					open={modalState.open}
					onConfirm={handleLogout}
					onCancel={() => setModalState(INITIAL_MODAL_STATE)}
				>
					<Typography textAlign='center'>Are you sure you want to Logout?</Typography>
				</MMSCANDialog>
			)}
		</>
	);
};

export default ProfileMenu;
