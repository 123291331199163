import { SxProps } from '@mui/material';
import { colors } from 'assets/styles/MMSCANTheme';
import * as CommonSx from 'assets/styles/Common.style';

export const listItemStyle = {
	display: 'flex',
	gap: '3px',
	padding: '8px 10px',
	flexDirection: 'column',
	alignItems: 'flex-start',
	borderRadius: '4px',
	marginBottom: '10px',
	cursor: 'pointer',
	position: 'relative'
} as SxProps;

export const notificationButton = {
	position: 'fixed',
	top: '50%',
	transform: 'translateY(-50%)',
	zIndex: '1201',
	transition: 'right 0.225s ease',
	cursor: 'pointer'
} as SxProps;

export const notificationTitleBox = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '10px 20px',
	zIndex: '1',
	width: '100%',
	backgroundColor: colors.white.main
} as SxProps;

export const notificationTitle = {
	fontWeight: 500,
	fontSize: '14px'
} as SxProps;

export const notificationBottomText = {
	color: colors.secondary.shade5,
	fontSize: '12px'
} as SxProps;

export const notificationStyles = {
	markAllAsRead: (unreadCount: number) => ({
		cursor: unreadCount > 0 ? 'pointer' : 'initial',
		fontSize: '12px',
		fontWeight: 500,
		textAlign: 'right',
		color: unreadCount > 0 ? colors.primary.main : colors.secondary.light,
		width: 'fit-content'
	}),
	noDataText: {
		fontSize: '14px',
		textAlign: 'center',
		marginTop: '30px'
	},
	unreadIndicator: {
		height: '6px',
		width: '6px',
		borderRadius: '50%',
		background: colors.primary.main,
		position: 'absolute',
		top: '6px',
		right: '6px'
	}
};

export const notificationChipSx = (selectedColor: string, isChipSelected: boolean, label: string) => ({
	...CommonSx.filteredChipStyle,
	marginRight: '5px',
	height: '22px',
	boxShadow: isChipSelected
		? '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
		: '',
	color: label === 'All' ? '' : colors.black.main,
	background: selectedColor,
	border: `1px solid ${isChipSelected ? colors.primary.main : selectedColor}`,
	'&:hover': {
		backgroundColor: selectedColor,
		opacity: '0.8'
	}
});

export const showMoreText = {
	width: 'fit-content',
	fontSize: '12px',
	cursor: 'pointer',
	color: colors.primary.main,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
};
