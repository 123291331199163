import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import HttpService from 'shared/services/Http.service';
import { API_CONFIG } from 'shared/constants/api';
import { ROUTES } from 'shared/constants/constant';

import { StyledAuthTitle } from 'assets/styles/Common.style';
import ActionAlert from '../forgot-password/components/ActionAlert';

const VerifyAccountScreen: FC = () => {
	const { token, userId } = useParams<{ token: string; userId: string }>();
	const navigate = useNavigate();

	const [verifyUserId, setVerifyUserId] = useState('');
	// Combined state for verification
	const [verificationState, setVerificationState] = useState({
		isLoading: false,
		isBtnLoading: false,
		isVerifySuccess: false,
		isResendSuccess: false,
		isResendEnable: false,
		isInvalidToken: false,
		isAlreadyVerify: false,
		isCommonError: false
	});
	const [errorMessage, setErrorMessage] = useState('');

	// Helper function to update state
	const updateVerificationState = useCallback((updates: Partial<typeof verificationState>) => {
		setVerificationState((prevState) => ({ ...prevState, ...updates }));
	}, []);

	// Verify email function
	const verifyEmail = useCallback(
		async (token: string, user_id: string) => {
			updateVerificationState({ isLoading: true });
			try {
				const params = { token, user_id };
				await HttpService.post(API_CONFIG.verifyAccount, params);
				updateVerificationState({ isLoading: false, isVerifySuccess: true });
			} catch (error: any) {
				const status = error.response?.status;
				const errorCode = [400, 401, 409];
				setErrorMessage(error?.response?.data?.message);
				updateVerificationState({
					isLoading: false,
					isResendEnable: status === 400,
					isInvalidToken: status === 401,
					isAlreadyVerify: status === 409,
					isCommonError: !errorCode.includes(status)
				});
			}
		},
		[updateVerificationState]
	);

	// Resend verification function
	const resendVerification = useCallback(async () => {
		updateVerificationState({ isBtnLoading: true });
		try {
			await HttpService.post(API_CONFIG.resendVerificationEmail, { user_id: verifyUserId });
			updateVerificationState({ isBtnLoading: false, isResendSuccess: true });
		} catch {
			updateVerificationState({ isBtnLoading: false });
		}
	}, [updateVerificationState, verifyUserId]);

	// Effect to handle initial verification
	useEffect(() => {
		if (token && userId) {
			verifyEmail(token, userId);
			setVerifyUserId(userId);
		} else {
			navigate(ROUTES.LOGIN);
		}
	}, [token, userId, verifyEmail, navigate, updateVerificationState]);

	const {
		isLoading,
		isBtnLoading,
		isVerifySuccess,
		isResendSuccess,
		isResendEnable,
		isInvalidToken,
		isAlreadyVerify,
		isCommonError
	} = verificationState;

	return (
		<>
			<>
				<StyledAuthTitle data-testid='resetPasswordTitle'>Account Verification</StyledAuthTitle>

				{isCommonError && (
					<ActionAlert
						message={errorMessage}
						severity='error'
						buttonProps={{
							text: 'Go To Login',
							onClick: () => navigate(ROUTES.LOGIN),
							loading: isLoading,
							'data-testid': 'loginButton',
							fullWidth: true
						}}
					/>
				)}

				{isInvalidToken && (
					<ActionAlert
						message='Link is expired. Please try again.'
						severity='error'
						buttonProps={{
							text: 'Go To Login',
							onClick: () => navigate(ROUTES.LOGIN),
							loading: isLoading,
							'data-testid': 'loginButton',
							fullWidth: true
						}}
					/>
				)}

				{isAlreadyVerify && (
					<ActionAlert
						message='Your email has already been verified.'
						severity='error'
						buttonProps={{
							text: 'Go To Login',
							onClick: () => navigate(ROUTES.LOGIN),
							loading: isLoading,
							'data-testid': 'loginButton',
							fullWidth: true
						}}
					/>
				)}

				{isVerifySuccess && !isResendEnable && (
					<ActionAlert
						message='Your account has been verified successfully.'
						severity='success'
						buttonProps={{
							text: 'Go To Login',
							onClick: () => navigate(ROUTES.LOGIN),
							loading: isLoading,
							'data-testid': 'loginButton',
							fullWidth: true
						}}
					/>
				)}

				{isResendEnable && !isResendSuccess && (
					<ActionAlert
						message='Your Account verification has been failed. Click the below button to resend the verification email.'
						severity='error'
						buttonProps={{
							text: 'Resend Email',
							onClick: resendVerification,
							loading: isBtnLoading,
							'data-testid': 'refreshTokenButton',
							fullWidth: true
						}}
					/>
				)}

				{isResendSuccess && (
					<ActionAlert message='Email sent successfully. Please check your email.' severity='success' />
				)}
			</>
		</>
	);
};

export default VerifyAccountScreen;
