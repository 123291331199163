import * as actionTypes from 'store/action-types';
import { IAction, ICommonFilterState } from 'shared/interface/state';
import { ICommonPagination, IFilterParams } from 'shared/interface';

export const businessPlanInitialState: ICommonFilterState = {
	pagination: {} as ICommonPagination,
	filter: {} as IFilterParams
};

const businessPlanReducer = (
	state: ICommonFilterState = businessPlanInitialState,
	action: IAction
): ICommonFilterState => {
	switch (action.type) {
		case actionTypes.BUSINESS_PLAN:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default businessPlanReducer;
