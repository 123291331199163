import { SxProps } from '@mui/material';
import { colors } from 'assets/styles/MMSCANTheme';

const mainRoot = {
	height: '103px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: '0 20px 0 10px',
	position: 'sticky',
	gap: '20px',
	top: '0',
	backgroundColor: colors.secondary.shade4,
	zIndex: 10
} as SxProps;

const timelineRoot = {
	fontSize: '12px',
	backgroundColor: colors.primary.shade5,
	border: `1px dashed ${colors.primary.main}`,
	borderRadius: '20px',
	padding: '5px 10px',
	animation: 'zoom-in-zoom-out 2s ease-out infinite'
} as SxProps;

const timeline = {
	color: colors.primary.main,
	fontSize: 'inherit',
	fontWeight: 'inherit'
} as SxProps;

const timelineBold = {
	color: 'inherit',
	fontSize: 'inherit',
	fontWeight: 600
} as SxProps;

const infoRoot = {
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer'
} as SxProps;

const infoBox = {
	display: 'flex',
	alignItems: 'center',
	marginLeft: '12px',
	cursor: 'pointer'
} as SxProps;

const avatar = {
	width: '40px',
	height: '40px'
} as SxProps;

const profileName = {
	height: '50px',
	width: '50px',
	minWidth: '50px',
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: colors.black.shade2,
	color: colors.white.main
} as SxProps;
const companyName = { fontSize: '12px', fontWeight: 500, color: colors.secondary.shade5 } as SxProps;
const menuItem = { '&:focus:not(:hover)': { backgroundColor: 'transparent' }, fontSize: '12px' } as SxProps;
const icon = { fontSize: ' large' } as SxProps;

const logoWrapper = {
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	margin: '3px 0'
};

export {
	mainRoot,
	timelineRoot,
	timeline,
	timelineBold,
	avatar,
	infoRoot,
	profileName,
	companyName,
	menuItem,
	icon,
	infoBox,
	logoWrapper
};
