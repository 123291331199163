import { FC } from 'react';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
// Components
import MMSCANButton from '../MMSCANButton/MMSCANButton';
// Styles
import * as Sx from 'assets/styles/Common.style';

interface IProps extends DialogProps {
	onConfirm: () => void;
	onCancel: () => void;
	title?: string;
	loading?: boolean;
	confirmText?: string;
	isConfirmBtn?: boolean;
	cancelText?: string;
	disabled?: boolean;
	isCancelDisable?: boolean;
	isCrossIcon?: boolean;
	submitBtnType?: string;
	isOutSideClick?: boolean;
}

type IButtonType = 'button' | 'reset' | 'submit';

const MMSCANDialog: FC<IProps> = (props) => {
	const {
		open,
		children,
		title,
		onConfirm,
		onCancel,
		disabled,
		confirmText,
		cancelText,
		loading,
		PaperProps,
		maxWidth,
		isConfirmBtn = true,
		isCrossIcon = false,
		isCancelDisable = false,
		isOutSideClick = true,
		submitBtnType = 'button',
		...rest
	} = props;
	return (
		<Dialog
			open={open}
			fullWidth
			data-testid='dialogBox'
			PaperProps={{ sx: { ...PaperProps?.sx, borderRadius: '6px' } }}
			maxWidth={maxWidth || 'xs'}
			onClose={() => (isOutSideClick ? onCancel() : {})}
			{...rest}
		>
			<Box position={isCrossIcon ? 'relative' : 'static'}>
				{title && <DialogTitle sx={{ fontSize: '20px', textAlign: 'center' }}>{title}</DialogTitle>}
				{isCrossIcon && (
					<Box sx={Sx.dialogCloseButton} onClick={onCancel}>
						<Close sx={Sx.dialogCloseIcon} />
					</Box>
				)}
			</Box>
			<DialogContent>{children}</DialogContent>
			<DialogActions sx={{ alignSelf: 'center', padding: '0 20px 20px' }}>
				<MMSCANButton
					text={cancelText || 'Cancel'}
					sx={Sx.lightPrimaryBtn}
					onClick={onCancel}
					data-testid='dialogCancelBtn'
					isSmall={true}
					disabled={isCancelDisable}
				/>
				{isConfirmBtn ? (
					<MMSCANButton
						text={confirmText || 'Confirm'}
						onClick={onConfirm}
						loading={loading || false}
						data-testid='dialogConfirmBtn'
						disabled={disabled || false}
						isSmall={true}
						type={submitBtnType as IButtonType}
					/>
				) : (
					<></>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default MMSCANDialog;
