import { FC } from 'react';
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import { Alert, Typography } from '@mui/material';
import MMSCANButton from 'shared/components/MMSCANButton/MMSCANButton';
import { CommonLinkSxProps } from 'assets/styles/Common.style';

interface IErrorAlertProps {
	message: string;
	buttonText: string;
	buttonRoute: string;
	linkText: string;
	linkRoute: string;
	navigate: (route: string) => void;
}

const ErrorAlert: FC<IErrorAlertProps> = ({ message, buttonText, buttonRoute, linkText, linkRoute, navigate }) => {
	return (
		<>
			<Alert variant='outlined' severity='error' sx={{ marginBottom: '20px' }}>
				{message}
			</Alert>
			<MMSCANButton data-testid='loginButton' fullWidth text={buttonText} onClick={() => navigate(buttonRoute)} />
			<Typography data-testid='forgotPasswordLink' sx={CommonLinkSxProps} onClick={() => navigate(linkRoute)}>
				{linkText} <ArrowRightAltIcon fontSize='small' />
			</Typography>
		</>
	);
};

export default ErrorAlert;
