import { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButtonProps } from '@mui/lab';

interface IProps extends LoadingButtonProps {
	text?: string;
	isSmall?: boolean;
}

const MMSCANButton: FC<IProps> = (props) => {
	const { text = 'Default Text', isSmall, sx, ...rest } = props;
	return (
		<LoadingButton
			variant='contained'
			size={isSmall ? 'small' : 'large'}
			sx={{ fontSize: isSmall ? '14px' : '16px', textTransform: 'none', minWidth: 'fit-content', ...sx }}
			{...rest}
		>
			{text}
		</LoadingButton>
	);
};

export default MMSCANButton;
