import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

import MMSCANLogo from 'assets/images/mmscan-logo.png';
import { StyledAuthContainer } from 'assets/styles/Common.style';
import {
	layoutStyles,
	loginFormWrapperStyle,
	bottomTextStyles,
	layoutInnerWrapperStyles
} from 'hoc/layout/Layout.styles';
import LoginBg from 'assets/images/LoginBG.png';

interface IProps extends PropsWithChildren {}

const PublicLayout: FC<IProps> = (props) => {
	return (
		<Box sx={layoutStyles}>
			<img src={LoginBg} className='login-bg-cars' />
			<Box sx={layoutInnerWrapperStyles}>
				<Box sx={{ width: '60%', height: '100%' }} />
				<Box sx={loginFormWrapperStyle}>
					<StyledAuthContainer variant='outlined'>
						<Box sx={{ height: '70px' }}>
							<img
								alt='BizDrive logo'
								src={MMSCANLogo}
								style={{ height: '100%' }}
								data-testid='MMSCANLogo'
							/>
						</Box>
						{props.children}
					</StyledAuthContainer>
					<Typography sx={bottomTextStyles}>BizDrive</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default PublicLayout;
