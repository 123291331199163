import * as actionTypes from 'store/action-types';
import { IAction, ICommonFilterState } from 'shared/interface/state';
import { ICommonPagination, IFilterParams } from 'shared/interface';

export const userDataInitialState: ICommonFilterState = {
	pagination: {} as ICommonPagination,
	filter: {} as IFilterParams
};

const userReducer = (state: ICommonFilterState = userDataInitialState, action: IAction): ICommonFilterState => {
	switch (action.type) {
		case actionTypes.USER_DATA:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default userReducer;
