import React from 'react';
import { Tooltip } from '@mui/material';
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip/Tooltip';
import { colors } from 'assets/styles/MMSCANTheme';

interface IProps {
	offset?: number[];
}

const MMSCANTooltip = ({ children, offset = [10, -10], ...props }: IProps & MuiTooltipProps): React.JSX.Element => (
	<Tooltip
		data-testid='tooltip'
		PopperProps={{
			modifiers: [
				{
					name: 'offset',
					options: {
						offset
					}
				}
			]
		}}
		slotProps={{
			tooltip: {
				style: { backgroundColor: colors.grey.shade2 }
			}
		}}
		{...props}
	>
		{children}
	</Tooltip>
);

export default MMSCANTooltip;
