import moment, { Moment } from 'moment';
import { dateFormate } from 'shared/util/Utility';
import { EMAIL_REGEX, FAX_REGEX, PASSWORD_REGEX, PHONE_REGEX, WEBSITE_REGEX, DEALER_CODE_REGEX } from './constant';

export const mandateValidation = {
	required: {
		value: true,
		message: 'This field is required'
	}
};

export const validation = {
	required: {
		...mandateValidation
	},
	email: {
		...mandateValidation,
		pattern: {
			value: EMAIL_REGEX,
			message: 'Invalid email address'
		}
	},
	password: {
		...mandateValidation,
		pattern: {
			value: PASSWORD_REGEX,
			message:
				'Password must contain at least 8 to 16 characters, including at least one uppercase letter, one lowercase letter, one special character and one digit.'
		}
	}
};

export const generateValidation = (fieldName: string) => {
	const mandateValidation = {
		required: {
			value: true,
			message: `${fieldName} is required`
		}
	};

	return {
		required: { ...mandateValidation },
		dealer_code: {
			...mandateValidation,
			pattern: {
				value: DEALER_CODE_REGEX,
				message: 'Dealer code must be exactly five digits long.'
			}
		},
		first_name: {
			...mandateValidation,
			maxLength: {
				value: 50,
				message: `${fieldName} cannot exceed 50 characters`
			}
		},
		last_name: {
			...mandateValidation,
			maxLength: {
				value: 50,
				message: `${fieldName} cannot exceed 50 characters`
			}
		},
		dba: {
			...mandateValidation,
			maxLength: {
				value: 50,
				message: `${fieldName} cannot exceed 50 characters`
			}
		},
		job_title: {
			...mandateValidation,
			message: 'Job Title is required'
		},
		email: {
			...mandateValidation,
			pattern: {
				value: EMAIL_REGEX,
				message: 'Invalid email address'
			}
		},
		password: {
			...mandateValidation,
			pattern: {
				value: PASSWORD_REGEX,
				message:
					'Password must be 8 to 16 characters long, including at least one uppercase letter, one lowercase letter, one special character and one digit.'
			}
		},
		website: {
			...mandateValidation,
			pattern: {
				value: WEBSITE_REGEX,
				message: 'Please enter a valid website URL.'
			}
		},
		phone: {
			pattern: {
				value: PHONE_REGEX,
				message:
					'Phone number must contain only digits, parentheses, dashes, and plus signs, and must be between 8 to 12 characters long.'
			}
		},
		fax: {
			pattern: {
				value: FAX_REGEX,
				message: 'Fax number can only contain digits, spaces, parentheses, dashes, and plus signs'
			}
		},
		invalidEmail: {
			pattern: {
				value: EMAIL_REGEX,
				message: 'Invalid email address'
			}
		},
		comments: {
			...mandateValidation,
			maxLength: {
				value: 300,
				message: `${fieldName} cannot exceed 300 characters`
			}
		},
		plan_name: {
			...mandateValidation,
			minLength: {
				value: 2,
				message: `${fieldName} must be at least 2 characters long`
			},
			maxLength: {
				value: 100,
				message: `${fieldName} cannot exceed 100 characters`
			}
		}
	};
};

export const validateName =
	(fieldName: string) =>
	(value: string): string | boolean => {
		if (!value?.trim()) {
			return `${fieldName} is required`;
		}
		return true; // No error
	};

export const validatePasswordConfirmation = (newPassword: string, confirmPassword: string) => {
	if (newPassword !== confirmPassword) {
		return 'Password do not match';
	}
	return true; // No error
};

export const validateEndDate = (
	endDateVal: Moment | string,
	endTimeVal: Moment | string,
	startDateVal: Moment | string,
	startTimeVal: Moment | string
) => {
	// Convert Moment objects to formatted strings
	const formattedStartDate = dateFormate(startDateVal);
	const formattedEndDate = dateFormate(endDateVal);

	const formattedStartTime = dateFormate(startTimeVal, 'HH:mm');
	const formattedEndTime = dateFormate(endTimeVal, 'HH:mm');

	// Combine start date and time
	const startDateTime = moment(`${formattedStartDate} ${formattedStartTime}`, 'YYYY-MM-DD HH:mm');
	// Combine end date and time
	const endDateTime = moment(`${formattedEndDate} ${formattedEndTime}`, 'YYYY-MM-DD HH:mm');

	// Validate that endDateTime is after startDateTime and valid
	if (endDateTime.isValid() && endDateTime.isAfter(startDateTime)) {
		return true; // No validation error
	} else {
		return 'End date and time should not be less than or equal to start date and time.';
	}
};
