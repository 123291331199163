import * as actionTypes from 'store/action-types';
import { IAction, IAuthState } from 'shared/interface/state';
import AuthService from 'shared/services/Auth.service';

export const initialState: IAuthState = {
	isLoggedIn: AuthService.checkLogin(),
	...AuthService.getAuthData()
};

const reducer = (state: IAuthState = initialState, action: IAction): IAuthState => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			};
		case actionTypes.AUTH_FAIL:
			AuthService.removeAuthData();
			AuthService.clearLocalStorage();
			return {
				isLoggedIn: false
			};
		default:
			return state;
	}
};
export default reducer;
