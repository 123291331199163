import * as actionTypes from 'store/action-types';
import { IAction } from 'shared/interface/state';
import { IAgendaActionState } from '../VisitManagement.type';

export const agendaActionInitialState: IAgendaActionState = {
	screen: 0
};

const agendaActionReducer = (
	state: IAgendaActionState = agendaActionInitialState,
	action: IAction
): IAgendaActionState => {
	switch (action.type) {
		case actionTypes.AGENDA_ACTION:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default agendaActionReducer;
