import moment from 'moment';
import * as actionTypes from 'store/action-types';
import { IAction } from 'shared/interface/state';
import { IClosedDataFilter } from 'shared/interface';

export const closedDataInitialState: IClosedDataFilter = {
	year: moment().year()
};

const closedDataReducer = (state = closedDataInitialState, action: IAction): IClosedDataFilter => {
	switch (action.type) {
		case actionTypes.CLOSED_DATA:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default closedDataReducer;
